<template>
  <div class="index-login">
    <div class="row">
      <div class="col-1 login-left"></div>

      <div class="col-11">
        <div class="login-container">
          <form @submit.prevent="handlerLogin" class="pt-5 pb-5">
            <div class="login shadow-sm">
              <div class="mb-3">
                <img src="../../assets/logo.png" alt  width="150"/>
                <div class="login-icon">
                  <h3>
                    <em>Login</em>
                  </h3>
                </div>
                <label for="name">Username</label>
                <br />
                <input
                  type="text"
                  placeholder="username"
                  v-model.trim="user.username"
                  @blur="$v.user.username.$touch"
                />
                <template v-if="$v.user.username.$error">
                  <div v-if="!$v.user.username.required" class="error-message">Username is required</div>
                </template>
                <div class="mb-3">
                  <label for="name">Password</label>
                  <br />
                  <input
                    type="password"
                    placeholder="password"
                    v-model.trim="user.password"
                    @blur="$v.user.password.$touch"
                  />
                  <template v-if="$v.user.password.$error">
                    <div
                      v-if="!$v.user.password.required"
                      class="error-message"
                    >password is required</div>
                  </template>
                </div>
              </div>
              <template v-if="message">
                <p class="error-message">Incorrect username or password!</p>
              </template>
              <button
                :disabled="$v.$invalid"
                value="login"
                type="submit"
                class="btn btn-success"
              >Login</button>
              <div>
                <a href="/forgot-password">Forgot password?</a>
              </div>
              <div class="pt-2">
                <router-link to="/register">Register</router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-1 login-right"></div>
    </div>
  </div>
</template>

<script>
import User from '../../models/user';

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {
      user: new User("", ""),
      loading: false,
      message: "",
    };
  },
  validations: {
    user: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    handlerLogin() {
      this.loading = true;

      if (this.user.username && this.user.password) {
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            this.$router.push("/");
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
  },
};
</script>

<style scouped>
.index-login {

  padding: 1rem;
}
.empty {
  padding-bottom: 10%;
}
.login-container {
  text-align: center;
  width: 50%;
  margin-left: auto;
  padding: 5rem 10rem 10rem 10rem;
}
.login {
  background-color: #f8f9fa;
  margin: 0 auto;
  border-radius: 8%;
  padding: 3rem 0;
}

.error-message {
  color: red;
}

@media (max-width: 576px) {
  .login-container {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    padding: 5rem 0rem 10rem 0rem;
  }
  .login {
    background-color: transparent;
  }
  .register {
    background-color: transparent;
    padding: 0;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 1024px) {
    .login-container {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    padding: 5rem 0rem 10rem 0rem;
  }
  .login {
    background-color: transparent;
  }
  .register {
    background-color: transparent;
    padding: 0;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
</style>
